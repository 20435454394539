/**
 * Method for taking a list and creating a list with items groups pairwise
 */
export const pairwise = <T>(list: T[]): [T, T][] => {
    const ret: Array<[T, T]> = [];
    for (let i = 0; i < list.length; i += 2) {
        ret.push([list[i], list[i + 1]] as [T, T]);
    }
    return ret;
};
