export const FLAG_SHOPPING_CART_FOR_SRP = 'shopping_cart_for_wedding_venues_srp';
export const FLAG_SRP_PRICE_VENUE_CTA = 'srp_price_venue_cta';
export const FLAG_VENUE_PROFILE_PAGE_REVIEW = 'wedding_venue_profile_review';
export const FLAG_ROOM_BLOCK_RECOMMENDATIONS_SHOWN_ON_PROFILE = 'room_block_recommendations_shown_on_profile';
export const FLAG_DW_HOMEPAGE_ADS = {
    Cancun: 'show_dw_cancun_homepage_ad',
    Mexico: 'show_dw_mexico_homepage_ad',
    Jamaica: 'show_dw_jamaica_homepage_ad',
    'Dominican Republic': 'show_dw_dominican_republic_homepage_ad',
    'Caribbean Islands': 'show_dw_caribbean_islands_homepage_ad',
    'DR Punta Cana': 'show_dw_dr_punta_cana_homepage_ad',
};