import { range } from './fp';

export function getDaysInMonth(month: number, year: number): number {
    return new Date(year, month + 1, 0).getDate();
}

export function getPrevMonthNumber(month: number): number {
    if (month === 0) {
        return 11;
    }
    return month - 1;
}

export function getNextMonthNumber(month: number): number {
    if (month === 11) {
        return 0;
    }
    return month + 1;
}

export function getWeekDays(): Array<string> {
    return ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa'];
}

export function getMonths(): Array<string> {
    return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
}

export const getMonthName = (monthNumber: number): string => {
    return getMonths()[monthNumber];
};

export function getWeekDay(month: number, year: number, day: number): number {
    return new Date(year, month, day).getDay();
}

export function getDaysArray(month: number, year: number, onlyCurrentDates?: boolean): Array<number> {
    const daysInMonth: number = getDaysInMonth(month, year);
    const daysInPrevMonth: number = getDaysInMonth(getPrevMonthNumber(month), year);

    const firstWeekDay: number = getWeekDay(month, year, 1);
    const lastWeekDay: number = getWeekDay(month, year, daysInMonth);

    const monthDays: Array<number> = range(1, daysInMonth + 1);

    const monthStart: Array<number> = range(daysInPrevMonth - firstWeekDay + 1, daysInPrevMonth + 1);

    const monthEnd: Array<number> = range(1, 7 - lastWeekDay);

    if (onlyCurrentDates) {
        return [...monthStart.map(() => 0), ...monthDays, ...monthEnd.map(() => 0)];
    }

    return [...monthStart, ...monthDays, ...monthEnd];
}

export const addMonths = (currentDate: Date, monthsNumber: number) => {
    const outputDate = new Date(currentDate.getTime());
    outputDate.setMonth(outputDate.getMonth() + monthsNumber);
    return outputDate;
};

export const addDays = (currentDate: Date, days: number) => {
    const outputDate = new Date(currentDate.getTime());
    outputDate.setDate(outputDate.getDate() + days);
    return outputDate;
};
