/**
 * Given a URL string, determines if the image is compatible with the Cvent image optimization service
 * @param src image url
 */
export const isCventImage = (src: string) => {
    try {
        const srcUrl = new URL(src);
        return !!srcUrl.origin.match(/images-?.*\.cvent\.com/);
    } catch {
        return false;
    }
};

/**
 * Given an image URL, detect if the image is a cvent image service image,
 * if it is, then apply the formatting params, otherwise return the original url
 * with no params
 * @param src image url
 */
export const formatCventImage = (params: { src: string; resize?: string; strict?: boolean; quality?: number; format?: string }) => {
    const { src, resize, strict, quality, format } = params;

    try {
        if (!src || !isCventImage(src)) {
            return src;
        }

        const srcUrl = new URL(src);

        // Apply formatting
        const _resize = resize ? resize : '';
        const _strict = strict !== undefined ? strict : false;

        srcUrl.searchParams.set('unique', 'true');

        if (Boolean(_resize)) {
            const d = _strict ? _resize : _resize.split('x')[0];
            srcUrl.searchParams.set('d', d);
        }

        if (!!quality) {
            srcUrl.searchParams.set('q', `${quality}`);
        }

        if (!!format) {
            srcUrl.searchParams.set('f', `${format}`);
        }

        return srcUrl.href;
    } catch {
        return src;
    }
};

/**
 * Given a URL and a target width in pixels, generate a Cvent image service URL for the
 * image at the given width (preserving aspect ratio). If src is empty or the URL isn't a cvent image service URL
 * just return the original URL
 *
 * @param src image url
 * @param width the width of the target image in pixels
 */
export const getSizedImage = (src: string, width: number) => {
    return formatCventImage({ src, resize: `${width}` });
};

/**
 * Recursively runs getSizedImage, for each of the provided widths in pixels
 *
 * @param src image url
 * @param widths the target widths
 */
export const getSizedImages = (src: string, widths: number[]) => {
    return widths.map((w) => getSizedImage(src, w));
};
