export function coordinatesIntersection(x1: number, x2: number, y1: number, y2: number): [number, number] {
    let inter1 = -1;
    let inter2 = -1;

    if (x1 >= y2 || x2 <= y1) {
        return [inter1, inter2];
    }

    if (x1 < y1) {
        inter1 = y1;

        if (x2 < y2) {
            inter2 = x2;
        } else {
            inter2 = y2;
        }
    } else {
        inter1 = x1;

        if (x2 < y2) {
            inter2 = x2;
        } else {
            inter2 = y2;
        }
    }

    return [inter1, inter2];
}

export const concat: <T>(first: T[], second: T[]) => T[] = (first, second) => clone(first).concat(clone(second));

export function arrayCoordinatesIntersections(arr: [number, number, number][]) {
    let intersections: [number, number, number, number][] = [];

    for (let i = 0, l = arr.length - 1; i < l; i++) {
        const [x1, x2] = arr[i];

        for (let j = i + 1, k = arr.length; j < k; j++) {
            const [y1, y2] = arr[j];
            const [inter1, inter2] = coordinatesIntersection(x1, x2, y1, y2);
            if (inter1 !== -1) {
                intersections.push([inter1, inter2, i, j]);
            }
        }
    }

    return intersections;
}

export function first<S>(arr: Array<S>): S {
    if (!arr.length) {
        throw 'Argument of "first" function required to have at least one element.';
    }
    return arr[0];
}

export function firstOrUndefined<S>(arr: Array<S>): S | undefined {
    return arr[0];
}

export function range(start: number, end: number): Array<number> {
    const ret: Array<number> = [];
    for (let i = start; i < end; i++) {
        ret.push(i);
    }
    return ret;
}

export interface EqDict {
    [name: string]: string | number;
}

export function removeByValue<A>(arr: Array<A>, elem: A) {
    const i = arr.indexOf(elem);
    const ret = [...arr];

    if (i !== -1) {
        ret.splice(i, 1);
    }
    return ret;
}

export function parseJSON<T>(data: string): T {
    return JSON.parse(data);
}

export function clone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj)) as T;
}

export function extend<T, P>(target: T, source: P): void {
    // Make "void" the return value to
    // clearify that this function modifies
    // the target.
    Object.assign(target, source);
}

export function assign<T, P>(target: T, source: P): T & P {
    return Object.assign({}, target, source);
}

export function arrayToDict<T>(key: number | string, arr: T[]): { [key: string]: T } {
    const ret = {};
    arr.map((el) => {
        ret[el[key]] = el;
    });
    return ret;
}

export function remove<T>(el: T, arr: T[]): T[] {
    const newArr = clone(arr);
    newArr.splice(newArr.indexOf(el), 1);
    return newArr;
}

export function all<T>(f: (o: T) => boolean, arr: Array<T>): boolean {
    for (let i = 0, l = arr.length; i < l; i++) {
        if (!f(arr[0])) {
            return false;
        }
    }

    return true;
}

export function findExact<T>(q: T, arr: Array<T>): T | null {
    const searchKeys = Object.keys(q);
    for (let i = 0, l = arr.length; i < l; i++) {
        const o = arr[i];
        if (
            all(
                (v) => v,
                searchKeys.map((key: string) => o[key] && o[key] === q[key])
            )
        ) {
            return o;
        }
    }
    return null;
}

export function any<T>(q: T, arr: Array<T>): T | null {
    for (let i = 0, l = arr.length; i < l; i++) {
        if (arr[i] === q) {
            return q;
        }
    }
    return null;
}

export function findIndexByKeyValue<T>(q: T, arr: Array<T>): number {
    const searchKeys = Object.keys(q);
    for (let i = 0, l = arr.length; i < l; i++) {
        const o = arr[i];
        if (
            all(
                (v) => v,
                searchKeys.map((key: string) => o[key] && o[key] === q[key])
            )
        ) {
            return i;
        }
    }
    return -1;
}

export function flatten<T>(list: T[][]): T[] {
    let flatList: T[] = [];

    list.map((el) => {
        flatList = flatList.concat(el);
    });
    return flatList;
}
