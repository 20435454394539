/**
 * Takes a full name string and returns an array of strings, w/ maximum length 2, where
 * first token is the first token and second token is the concatenation of all remaining
 * tokens. If the array has only 1 token, an array of length 1 is returned; if the string
 * contains no valid tokens, returns null.
 */
export function tokenizeFullName(fullName: string | undefined): Array<string> | null {
    if (fullName === undefined) {
        return null;
    }

    let tokenized = fullName.split(' ');

    if (!tokenized) {
        return null;
    }

    // Remove empty tokens
    tokenized = tokenized.filter((t) => !!t);

    if (tokenized.length === 0) {
        return null;
    }

    if (tokenized.length < 2) {
        return tokenized;
    }

    let fname = tokenized[0];
    let lname = tokenized.slice(1).join(' ');

    return [fname, lname];
}

/**
 * Try to parse an integer from a string; if a value can't be parsed, return
 * defRet, otherwise undefined
 */
export function tryParseInt<D>(srcStr: string | undefined, defRet: D) {
    const parsed = parseInt(srcStr || '', 10);
    if (parsed !== NaN) {
        return parsed;
    }

    return defRet;
}

/* Convert a number to US dollars as a string */
export const toUSD = (value: number, showCents: boolean = false): string => {
    const nf = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: showCents ? 2 : 0,
        maximumFractionDigits: showCents ? 2 : 0,
    });
    return nf.format(value);
};

/* Do not show cents if they are 0-s */
export const toggleZeroes = (srcNum: string): string => {
    const [num, cents] = srcNum.split('.');
    if (cents === '00') {
        return srcNum;
    }
    return num;
};

/* Given a decimal number, only show the cents if there is a nonzero value */
export const formatPrice = (price: number, showZeroes: boolean = true) => toggleZeroes(toUSD(price, showZeroes));
