import * as React from 'react';
import { Flags, FlagsApi } from '@weddingspot/ws-api-client';
import { useEffect } from 'react';

type FeatureFlag = {
    name: string;
    enabled: boolean;
};

type FeatureFlagContextType = {
    featureFlags: FeatureFlag[];
    refreshFeatureFlags: () => Promise<FeatureFlag[]>;
    flagEnabled: (name: string) => boolean;
    isInitialized: boolean;
};

export const FeatureFlagContext = React.createContext<FeatureFlagContextType>({
    featureFlags: [],
    refreshFeatureFlags: () => Promise.resolve([]),
    flagEnabled: (name: string) => false,
    isInitialized: false,
});

export const FeaturedFlagsContextProvider = (props: { children: React.ReactNode; isBot?: boolean }) => {
    const [featureFlags, setFeatureFlags] = React.useState<Flags.Flag[]>([]);
    const [isInitialized, setIsInitialized] = React.useState<boolean>(Boolean(props.isBot));

    useEffect(() => {
        if (!props.isBot) {
            FlagsApi.getFlags().then((result: Flags.Flag[]) => {
                setFeatureFlags(result);
                setIsInitialized(true);
            });
        }
    }, []);

    const refreshFeatureFlags = () => {
        if (!props.isBot) {
            return FlagsApi.getFlags().then((result: Flags.Flag[]) => {
                setFeatureFlags(result);
                return result;
            });
        }
        return Promise.reject('Something went wrong');
    };

    const flagEnabled = (name: string) => {
        if (props.isBot) {
            return false;
        }
        const flag = featureFlags.find((item: Flags.Flag) => item.name === name);
        if (!flag) {
            return false;
        }
        return flag.enabled;
    };

    return (
        <FeatureFlagContext.Provider
            value={{
                featureFlags,
                flagEnabled,
                refreshFeatureFlags,
                isInitialized,
            }}
        >
            {props.children}
        </FeatureFlagContext.Provider>
    );
};

export * from './constants';
