/*
 * According to usage only POCO objects could be cloned
 * so any not serializable property would not be passed to a copy
 */
export const cloneDeep = <T>(obj: T): T => {
    if (obj === undefined) {
        return obj;
    }
    return JSON.parse(JSON.stringify(obj));
};
