import { Auth, AuthProvider } from '@weddingspot/ws-api-client';
import { BUSINESS_UNIT, COMPONENT, GUEST_USER_ID, PERSONA, PRODUCT } from '../constants';

export const getBaseProps = (env: string, version: string, ) => {
    const baseProps = {
        'cvent_appVersion': version,
        'cvent_businessUnit': BUSINESS_UNIT,
        'cvent_component': COMPONENT,
        'cvent_environmentName': env,
        'cvent_pathname': window?.location?.pathname,
        'cvent_product': PRODUCT,
        'cvent_trackingPlanName': PRODUCT,
        'cvent_url': window?.location?.pathname + window?.location?.search,
    }
    return baseProps;
} 

export const prepareUserProps = (env: string, version: string, userData?: Auth.UserInfo) => {
    const baseProps = getBaseProps(env, version);
    let userProps = {
        ...baseProps, 
        'cvent_persona': userData?.is_wedding_vendor ? PERSONA.Vendor : PERSONA.Planner,
        'cvent_userId': userData ? userData.external_id : GUEST_USER_ID,
        'cvent_ddUserId': userData ? userData.external_id : GUEST_USER_ID,
    }
    return userProps;
}

export const getUserProps = (env: string, version: string): any => {
    return new Promise(async (resolve) => {
        AuthProvider.isLoggedIn().then((res: any) => {
            if(res == 'authed'){
                AuthProvider.userInfo().then((res: Auth.UserInfo) => {
                    resolve(prepareUserProps(env, version, res));
                });
            } else {
                resolve(prepareUserProps(env, version));
            }
        });
    });
}