import { ApiDetailResponse, Auth } from '@weddingspot/ws-api-client';
import Link from '../../components/link';

export enum AUTH_PROMPT_TYPE {
    SIGNUP = 'signup',
    SIGNIN = 'signin',
}

/**
 * Put any global application context in here, this is an attempt to make shared global
 * application components a more portable so we don't need to worry about redux shenanigans
 * to handle application level events
 */
export interface WSAppContextState {
    // User infor state vars
    user: UserInfo | null;
    userIsAuthenticated: boolean;

    // Auth modal open/closed states
    authModalOpen: boolean;
    signupModalOpen: boolean;
    isForceAuth?: boolean;

    // CCPA URL used in signup modal
    ccpaDoNotSellUrl: string;

    // Root node ID for rendering modals via portals API
    rootNodeId: string;

    // If context is used in ws-next package
    isNext: boolean;

    linkComponent: typeof Link;
}

export interface WSAppContextActions {
    loadUserData: () => Promise<UserInfo | null>;
    openAuthModal: (source?: string) => void;
    closeAuthModal: () => void;

    openSignupModal: () => void;
    closeSignupModal: () => void;
    setIsForceAuth: (value: boolean) => void;

    createNewAccount: (data: SignupFormData) => Promise<ApiDetailResponse>;
    onCreateNewAccountSuccess?: () => void;
    signIn: (data: AuthFormData) => Promise<void>;

    // Trigger action function if user is authenticed, if user is not authenticated show the prompt
    // of the given type
    promptIfUnauthed: (prompt: AUTH_PROMPT_TYPE, action: () => void) => void;
    getSRPViewFromSessionStorage: (venueId: number) => string | null;
    setSRPViewInSessionStorage: (venueId: number, srp_view: string | null) => void;
    removeSRPViewInSessionStorage: (venueId: number) => void;
}

export interface WSAppContextType extends WSAppContextState, WSAppContextActions {}

export interface UserInfo {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    isStaff: boolean;
    isSuperuser: boolean;
    unreadMessagesCount: number;
    dateJoined: Date;
    lastMarket: string;
    isWeddingVendor: boolean;
    isPaidWeddingVendor: boolean;
    numFavorites: number;
    lastEstimateDate?: Date;
    lastAppointmentDate?: Date;
    external_id?: string;
}

export interface Auth extends UserInfo {
    isAuthenticated: boolean;
}

export interface SignupFormData {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    password_confirm: string;
    grecaptcha_response: string;
}

export interface AuthFormData {
    email: string;
    password: string;
}

export interface UserAuth {
    user: UserInfo | null;
    userIsAuthenticated: boolean;
}
