// Typed isFunction util from:
// https://medium.com/@martin_hotell/react-children-composition-patterns-with-typescript-56dfc8923c64
// This is useful for components that use a "render props" pattern for children
type IsFunction<T> = T extends (...args: any[]) => any ? T : never;
export const isFunction = <T extends {}>(value: T): value is IsFunction<T> => typeof value === 'function';

export const getDistanceFromCoordinates = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
    const deg2Rad = (deg: number) => {
        return (deg * Math.PI) / 180;
    };

    const r = 3958.8; // Earth radius in mi
    const dLat = deg2Rad(lat2 - lat1);
    const dLon = deg2Rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2Rad(lat1)) * Math.cos(deg2Rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return r * c;
};
