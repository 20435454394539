import { tokenizeFullName } from './string';

/**
 * A function that returns true if the the value is INVALID, and undefined otherwise
 */
export interface IValidatorFn {
    (value: string | undefined): true | undefined;
}

/**
 * A function that returns a string if the value is INVALID, and undefined otherwise
 */
export interface IValidator {
    (value: string | undefined): string | undefined;
}

/**
 * Validate that a value is truthy
 */
const required: IValidatorFn = (value: string | undefined) => (value ? undefined : true);

/**
 * Validate that the has exactly 2 tokens
 */
const notFirstAndLastName: IValidatorFn = (value: string | undefined) => {
    let tokens = tokenizeFullName(value);
    return !tokens || tokens.length < 2 ? true : undefined;
};

/**
 * Validate that the value is a valid email address
 */
const email: IValidatorFn = (value: string | undefined) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i.test(value) ? true : undefined;

/**
 * Validate that the value is a valid US phone number (no country code)
 */
const phoneNumber: IValidatorFn = (value: string | undefined) => {
    if (value === undefined || value === '') {
        return undefined;
    }
    return !/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/i.test(value) ? true : undefined;
};

/**
 * Validate that the given value is a valid URL for a website
 */
const website: IValidatorFn = (value: string | undefined) =>
    value && !/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(value)
        ? true
        : undefined;

/**
 * Return true if guest count in invalid (integer > 0 if defined)
 */
const invalidGuestCount: IValidatorFn = (value: string | undefined) => {
    if (value === undefined) {
        return undefined;
    }

    if (!value) {
        return true;
    }

    const parsed = parseFloat(value);
    if (!parsed) {
        return true;
    }

    const isInt = parsed % 1 === 0;
    if (!isInt) {
        return true;
    }

    if (parsed <= 0 || parsed > 10000) {
        return true;
    }

    return undefined;
};

/**
 * Return a function with one argument, field value, that returns the error string if
 * the required check fails for the field value, returns undefined otherwise
 */
const createValidator = (validatorFn: IValidatorFn, errorMessage: string): IValidator => {
    return (value: string | undefined) => (validatorFn(value) ? errorMessage : undefined);
};

/**
 * Same as createValidator, except it doesn't run validation if the value is undefined
 */
const createOptionalValidator = (validatorFn: IValidatorFn, errorMessage: string): IValidator => {
    return (value: string | undefined) => {
        if (value === undefined) {
            return undefined;
        }

        return validatorFn(value) ? errorMessage : undefined;
    };
};

export { required, email, phoneNumber, website, notFirstAndLastName, invalidGuestCount, createValidator, createOptionalValidator };
