import { SRFP_ACCESS_KEY, SRFP_BASE_URL, IS_PROD_ENV, IS_STAGING, IS_ALPHA } from '../config';
const URL = require('url-parse');

export const getPathname = (url: string): string => new URL(url).pathname;

const getReferrer = (): string => (!!document && document.referrer) || '';

export { getReferrer };

export const generateSRFPurl = (uids: string[]) => {
    return `${SRFP_BASE_URL}/?key=${SRFP_ACCESS_KEY}&uid=${uids.join(',')}`;
};

export const getAssetPrefix = () => {
    if (IS_PROD_ENV) {
        return 'https://cdn.wedding-spot.com';
    } else if (IS_STAGING) {
        return 'https://cdn-sg50.wedding-spot.com';
    } else if (IS_ALPHA) {
        return 'https://cdn-ap50.wedding-spot.com';
    }
    return 'https://cdn-ap50.wedding-spot.com';
};
