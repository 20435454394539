import { useState, useEffect } from 'react';

const SSR_WIDTH = 0;
// const SSR_HEIGHT = 0;
// const SSR_SIZE = {
//   width: SSR_WIDTH,
//   height: SSR_HEIGHT,
// };

export const TABLET_SCREEN_SIZE = 768;
const getWindowWidth = () => {
    return typeof window === 'undefined' ? SSR_WIDTH : window.innerWidth;
};

// previosly we have function which return width and height, but looks like in wasnt neccessary.
// When u need use height create new function, BUT READ BELOW ABOUT HYDROTATE PROBLEM!

// General idea:
// First render on SSR and frontend will be mobile version (even on desktop)
// Because we need have the same markup on SSR and frontend
// Then we check only once (in useEffect), if we on desktop, call useState, it will lead to correct rerender
export const useWiderThan = (width: number) => {
    // by default use SSR_WIDTH, for have the same markup, for hydrotate
    const [isWider, setWider] = useState(SSR_WIDTH > width);

    useEffect(() => {
        const handleResize = () => {
            const isWiderNow = getWindowWidth() > width;
            if (isWiderNow !== isWider) {
                // After first (correct hydrotate) render happens,
                // check if we need actually switch desktop <-> mobile
                setWider(isWiderNow);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [isWider]);

    return isWider;
};

// Make some action (handler) when was clicked outside DOM element (ref)
export const useOutsideClickHandler = (ref: React.RefObject<HTMLDivElement>, handler: (event: MouseEvent | TouchEvent) => void) => {
    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            if (!ref.current || ref.current.contains(event.target as Node)) {
                return;
            }

            handler(event);
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
};

const getScroll = () => {
    if (typeof window !== 'undefined') {
        return {
            scrollX: (window && window.scrollX) || 0,
            scrollY: (window && window.scrollY) || 0,
        };
    } else {
        return {
            scrollX: 0,
            scrollY: 0,
        };
    }
};

/**
 * Hook that monitors window scroll, and updates the object
 * at the end of each window scroll. It returns the current
 * offset X and Y position.
 * @param {boolean} onlyOnScrollEnd If true, fires the event only when the user stops scrolling.
 */
export const useWindowScroll = (onlyOnScrollEnd: boolean = true) => {
    const [windowScroll, setWindowScroll] = useState(getScroll());

    useEffect(() => {
        let scrollId: ReturnType<typeof setTimeout>;

        const handleScroll = () => {
            if (onlyOnScrollEnd) {
                clearTimeout(scrollId);
                scrollId = setTimeout(() => setWindowScroll(getScroll()), 200);
            } else {
                setWindowScroll(getScroll());
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return windowScroll;
};

interface Size {
    width: number | undefined;
    height: number | undefined;
}

export function useWindowSize(): Size {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState<Size>({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener('resize', handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}
