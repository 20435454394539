import * as React from 'react';
import { Auth, AuthProvider } from '@weddingspot/ws-api-client';
import { ClarityTags, identifyCurUser, setCurUserProps, setCurUserPropsOnce } from '@weddingspot/ws-analytics';

import { WSAppContextType, AUTH_PROMPT_TYPE, SignupFormData, AuthFormData, UserAuth } from './types';
import Link from '../../components/link';
import LinkRouter from '../../components/link/router';
import { StorableDataKeys } from '../../utils/storage';

export * from './types';

interface Props {
    rootNodeId: string;
    ccpaDoNotSellUrl: string;
    isNext?: boolean;
    linkComponent?: typeof Link;
    onOpenAuthModal?: (source?: string) => void;
    onCreateNewAccountSuccess?: () => void;
}

export const WSAppContext: React.Context<WSAppContextType> = React.createContext<WSAppContextType>({} as WSAppContextType);

/**
 * General context provider for managing wedding spot app state instances
 * (app-specific credentials, user auth, global modals, etc.)
 */
const WSAppContextProvider = (props: React.PropsWithChildren<Props>) => {
    // state
    const { onOpenAuthModal, ccpaDoNotSellUrl, onCreateNewAccountSuccess } =
        props;
    const [userAuth, setUserAuth] = React.useState<UserAuth>({ user: null, userIsAuthenticated: false });
    const [activeModal, setActiveModal] = React.useState<AUTH_PROMPT_TYPE | null>(null);
    const [isForceAuth, setIsForceAuth] = React.useState<boolean>(false);

    // user auth actions

    /**
     * Load/reload user data and update auth state
     */
    const loadUserData = async () => {
        try {
            const info = await getUserInfo();
            setUserAuth({ user: info, userIsAuthenticated: true });
            identifyCurUser(info.id, info.external_id);
            setCurUserProps({ name: ClarityTags.USER_REGISTERED, value: 'Yes' });

            setCurUserProps({
                 $first_name: info.firstName,
                 $last_name: info.lastName,
                 $email: info.email,
                 $created: info.dateJoined,
                 'Last Market': info.lastMarket || 'Not Set',
                 'Is Staff': info.isStaff,
                 'Is Wedding Vendor': info.isWeddingVendor,
                 'Wedding Vendor Paid': info.isPaidWeddingVendor,
                 'Num Favorites': info.numFavorites,
                 'Last Spot Estimate Date': info.lastEstimateDate,
                 'Last Appointment Request Date': info.lastAppointmentDate,
             });
            setCurUserPropsOnce({
                 'Wedding Spot ID': `${info.id}`,
                 'Is Registered': true,
             });
            const userType = info.isStaff ? 'Staff' : info.isWeddingVendor ? 'Vendor' : 'Couple';
            setCurUserProps({ name: ClarityTags.USER_TYPE, value: userType });

            return info;
        } catch (e) {
            setUserAuth({ user: null, userIsAuthenticated: false });

            setCurUserProps({});
            setCurUserProps({ name: ClarityTags.USER_REGISTERED, value: 'No' });

            return null;
        }
    };

    /**
     * Open the auth modal if the user is not authenticated. This is useful for
     * auth-sensitive actions like favoriting
     *
     * @param prompt which modal to prompt with (signup or signin)
     * @param action callback if user is authenticated
     */
    const promptIfUnauthed = (prompt: AUTH_PROMPT_TYPE, action: () => void, source?: string) => {
        if (!userAuth.userIsAuthenticated) {
            if (prompt === AUTH_PROMPT_TYPE.SIGNIN) {
                openAuthModal(source);
            } else {
                openSignupModal();
            }
        } else {
            action();
        }
    };

    /**
     * Open auth modal and close signup modal this
     */
    const openAuthModal = (source?: string) => {
        setActiveModal(AUTH_PROMPT_TYPE.SIGNIN);
        onOpenAuthModal && onOpenAuthModal(source);
    };

    /**
     * Close auth modal
     */
    const closeAuthModal = () => setActiveModal(null);

    /**
     * Open signup modal
     */
    const openSignupModal = () => setActiveModal(AUTH_PROMPT_TYPE.SIGNUP);

    /**
     * Close signup modal
     */
    const closeSignupModal = () => setActiveModal(null);

    /**
     * Gets srp view from session storage
     * @param venueId 
     */
    const getSRPViewFromSessionStorage = (venueId: number) => {
        return sessionStorage.getItem(StorableDataKeys.SRP_VIEW + venueId);
    };

    /**
     * Sets srp view in session storage
     * @param venueId 
     * @param srp_view 
     */
    const setSRPViewInSessionStorage = (venueId: number, srp_view: string | null) => {
        sessionStorage.setItem(StorableDataKeys.SRP_VIEW + venueId, (srp_view ? srp_view : ''));
    };

    /**
     * Removes srp view from session storage
     * @param venueId 
     */
    const removeSRPViewInSessionStorage = (venueId: number) => {
        sessionStorage.removeItem(StorableDataKeys.SRP_VIEW + venueId);
    };

    /**
     * Removes unused srp view session storage values
     */
    const removeUnusedSRPViewInSessionStorage = () => {
        Object.keys(sessionStorage).filter(function (key) {
            return key.includes(StorableDataKeys.SRP_VIEW);
        }).forEach(function (key) {
            sessionStorage.removeItem(key);
        });
    };

    // Load user data once on mount
    React.useEffect(() => {
        loadUserData();
        if(!(window.location.pathname.includes('/venue/') || window.location.pathname.includes('/pricing/'))){
            removeUnusedSRPViewInSessionStorage();
        }
    },              []);

    // default state
    const defaultState: WSAppContextType = React.useMemo(() => {
        return {
            // State
            user: userAuth.user,
            userIsAuthenticated: userAuth.userIsAuthenticated,
            authModalOpen: activeModal === AUTH_PROMPT_TYPE.SIGNIN,
            signupModalOpen: activeModal === AUTH_PROMPT_TYPE.SIGNUP,
            isForceAuth,
            ccpaDoNotSellUrl: props.ccpaDoNotSellUrl,
            rootNodeId: props.rootNodeId,
            isNext: props.isNext || false,
            linkComponent: props.linkComponent || LinkRouter,

            // Actions
            loadUserData,
            openAuthModal,
            closeAuthModal,
            openSignupModal,
            closeSignupModal,
            createNewAccount,
            signIn,
            promptIfUnauthed,
            onCreateNewAccountSuccess,
            setIsForceAuth,
            getSRPViewFromSessionStorage,
            setSRPViewInSessionStorage,
            removeSRPViewInSessionStorage,
        };
    },                                                   [
        userAuth.user,
        userAuth.userIsAuthenticated,
        activeModal,
        ccpaDoNotSellUrl,
        onOpenAuthModal,
        onCreateNewAccountSuccess,
    ]);

    return <WSAppContext.Provider value={defaultState}>{props.children}</WSAppContext.Provider>;
};

/**
 * TODO: Refactor below methods into a separate service they don't need to be in here...
 */

/**
 * Attempt to fetch user info an parse it from API, NOTE we explicitly don't catch the error here
 * since we want to let the downstream caller handle it
 */
const getUserInfo = () => {
    return AuthProvider.userInfo().then((info: Auth.UserInfo) => {
        return {
            id: info.id,
            external_id: info.external_id,
            firstName: info.first_name,
            lastName: info.last_name,
            email: info.email,
            phoneNumber: info.phone_number,
            isStaff: info.is_staff,
            isSuperuser: info.is_superuser,
            unreadMessagesCount: info.unread_messages_count,
            lastMarket: info.last_market,
            dateJoined: new Date(info.date_joined),
            isWeddingVendor: info.is_wedding_vendor,
            isPaidWeddingVendor: info.wedding_vendor_paid,
            numFavorites: info.num_favorites,
            lastEstimateDate: info.last_estimate_date ? new Date(info.last_estimate_date) : undefined,
            lastAppointmentDate: info.last_appointment_date ? new Date(info.last_appointment_date) : undefined,
        };
    });
};

/**
 * Create a new user account using email + password
 * @param data signup form data
 */
const createNewAccount = (data: SignupFormData) => {
    return AuthProvider.createAccount({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        password1: data.password,
        password2: data.password_confirm,
        grecaptcha_response: data.grecaptcha_response,
    });
};


/**
 * Sign in using email + password
 * @param data auth form data
 */
const signIn = (data: AuthFormData) => {
    return AuthProvider.login(data.email, data.password);
};

export { WSAppContextProvider };
export * from './types';
